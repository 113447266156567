@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700,800,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700,800,900&display=swap");
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.flex-center-start {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
}

body {
    font-family: 'Raleway', sans-serif;
    font-weight: normal;
    font-style: normal
}

.img {
    max-width: 100%;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -o-transition: .3s;
    transition: .3s
}

a,
.button {
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -o-transition: .3s;
    transition: .3s
}

a:focus,
.button:focus,
button:focus {
    text-decoration: none;
    outline: none
}

a:focus {
    color: #fff;
    text-decoration: none
}

a:focus,
a:hover,
.portfolio-cat a:hover,
.footer -menu li a:hover {
    text-decoration: none;
    color: #1F1F1F
}

a,
button {
    color: #1F1F1F;
    outline: medium none
}

h1,
h2,
h3,
h4,
h5 {
    font-family: 'Raleway', sans-serif;
    color: #1F1F1F
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: inherit
}

ul {
    margin: 0px;
    padding: 0px
}

li {
    list-style: none
}

p {
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
    color: #4D4D4D;
    margin-bottom: 13px;
    font-family: 'Raleway', sans-serif
}

label {
    color: #7e7e7e;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400
}

*::-moz-selection {
    background: #444;
    color: #fff;
    text-shadow: none
}

::-moz-selection {
    background: #444;
    color: #fff;
    text-shadow: none
}

::selection {
    background: #444;
    color: #fff;
    text-shadow: none
}

*::-webkit-input-placeholder {
    color: #cccccc;
    font-size: 14px;
    opacity: 1
}

*:-ms-input-placeholder {
    color: #cccccc;
    font-size: 14px;
    opacity: 1
}

*::-ms-input-placeholder {
    color: #cccccc;
    font-size: 14px;
    opacity: 1
}

*::placeholder {
    color: #cccccc;
    font-size: 14px;
    opacity: 1
}

h3 {
    font-size: 24px
}

.mb-65 {
    margin-bottom: 67px
}

.black-bg {
    background: #020c26 !important
}

.white-bg {
    background: #ffffff
}

.gray-bg {
    background: #f5f5f5
}



.overlay {
    position: relative;
    z-index: 0
}

.overlay::before {
    position: absolute;
    content: "";
    background-color: #1f1f1f;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: .5
}

.overlay2 {
    position: relative;
    z-index: 0
}

.overlay2::before {
    position: absolute;
    content: "";
    background-color: #131313;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.2
}

.section-padding {
    padding-top: 120px;
    padding-bottom: 120px
}

.pt-120 {
    padding-top: 120px
}

.owl-carousel .owl-nav div {
    background: transparent;
    height: 54px;
    left: 0px;
    line-height: 54px;
    position: absolute;
    text-align: center;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    width: 54px;
    font-size: 25px;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    left: 50px;
    font-size: 18px;
    line-height: 54px
}

.owl-carousel .owl-nav div.owl-next {
    left: auto;
    right: 50px
}

.owl-carousel .owl-nav div.owl-next i {
    position: relative;
    right: 0;
    top: 1px
}

.owl-carousel .owl-nav div.owl-prev i {
    position: relative;
    right: 1px;
    top: 0px
}

.owl-carousel:hover .owl-nav div {
    opacity: 1;
    visibility: visible
}

.owl-carousel:hover .owl-nav div:hover {
    color: #fff;
    background: #009DFF
}

.mb-20px {
    margin-bottom: 20px
}

.boxed-btn {
    background: #fff;
    color: #131313;
    display: inline-block;
    padding: 18px 44px;
    font-family: "Raleway", sans-serif;
    font-size: 14px;
    font-weight: 400;
    border: 0;
    border: 1px solid #009DFF;
    letter-spacing: 3px;
    text-align: center;
    color: #009DFF !important;
    text-transform: uppercase;
    cursor: pointer
}

.boxed-btn:hover {
    background: #009DFF;
    color: #fff !important;
    border: 1px solid #009DFF
}

.boxed-btn:focus {
    outline: none
}

.boxed-btn.large-width {
    width: 220px
}

.boxed-btn3 {
    background: #009DFF;
    color: #fff;
    display: inline-block;
    padding: 18px 44px;
    font-family: "Raleway", sans-serif;
    font-size: 14px;
    font-weight: 400;
    border: 0;
    border: 1px solid #009DFF;
    letter-spacing: 3px;
    text-align: center;
    color: #fff !important;
    text-transform: uppercase;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    cursor: pointer
}

.boxed-btn3:hover {
    background: #fff;
    color: #009DFF !important;
    border: 1px solid #009DFF
}

.boxed-btn3:focus {
    outline: none
}

.boxed-btn3.large-width {
    width: 220px
}

.boxed-btn2 {
    background: transparent;
    color: #fff;
    display: inline-block;
    padding: 18px 24px;
    font-family: "Raleway", sans-serif;
    font-size: 14px;
    font-weight: 400;
    border: 0;
    border: 1px solid #fff;
    letter-spacing: 2px;
    text-transform: uppercase
}

.boxed-btn2:hover {
    background: #fff;
    color: #131313 !important
}

.boxed-btn2:focus {
    outline: none
}

.line-button {
    color: #919191;
    font-size: 16px;
    font-weight: 400;
    display: inline-block;
    position: relative;
    padding-right: 5px;
    padding-bottom: 2px
}

.line-button::before {
    position: absolute;
    content: "";
    background: #919191;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0
}

.line-button:hover {
    color: #009DFF
}

.line-button:hover::before {
    background: #009DFF
}

.book_now {
    display: inline-block;
    font-size: 14px;
    color: #009DFF;
    border: 1px solid #009DFF;
    text-transform: capitalize;
    padding: 10px 25px
}

.book_now:hover {
    background: #009DFF;
    color: #fff
}

.section_title span {
    color: #009DFF;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 15px;
    display: block
}

.section_title h3 {
    font-size: 46px;
    font-weight: 400;
    line-height: 56px;
    color: #1F1F1F
}

@media (max-width: 767px) {
    .section_title h3 {
        font-size: 30px;
        line-height: 36px
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .section_title h3 {
        font-size: 36px;
        line-height: 42px
    }
}

@media (max-width: 767px) {
    .section_title h3 br {
        display: none
    }
}

.mb-100 {
    margin-bottom: 100px
}

@media (max-width: 767px) {
    .mb-100 {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .mobile_menu {
        position: absolute;
        right: 0px;
        width: 100%;
        z-index: 9
    }
}

.slicknav_menu .slicknav_nav {
    background: #fff;
    float: right;
    margin-top: 0;
    padding: 0;
    width: 95%;
    padding: 0;
    border-radius: 0px;
    margin-top: 5px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 11px
}

.slicknav_menu .slicknav_nav a:hover {
    background: transparent;
    color: #009DFF
}

.slicknav_menu .slicknav_nav a.active {
    color: #009DFF
}

@media (max-width: 767px) {
    .slicknav_menu .slicknav_nav a i {
        display: none
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .slicknav_menu .slicknav_nav a i {
        display: none
    }
}

.slicknav_menu .slicknav_nav .slicknav_btn {
    background-color: transparent;
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: -40px;
    position: relative;
    z-index: 99;
    border: 1px solid #ddd;
    top: 3px;
    right: 5px;
    top: -32px
}

.slicknav_menu .slicknav_nav .slicknav_btn .slicknav_icon {
    margin-right: 6px;
    margin-top: 3px;
    position: relative;
    padding-bottom: 3px;
    top: -11px;
    right: -5px
}

@media (max-width: 767px) {
    .slicknav_menu {
        margin-right: 0px
    }
}

.slicknav_nav .slicknav_arrow {
    float: right;
    font-size: 22px;
    position: relative;
    top: -9px
}

.slicknav_btn {
    background-color: transparent;
    cursor: pointer;
    margin-bottom: 10px;
    position: relative;
    z-index: 99;
    border: none;
    border-radius: 3px;
    top: 5px;
    padding: 5px;
    right: 5px;
    margin-top: -5px;
    top: -31px
}

.slicknav_btn {
    background-color: transparent;
    cursor: pointer;
    margin-bottom: 10px;
    position: relative;
    z-index: 99;
    border: none;
    border-radius: 3px;
    top: 5px;
    padding: 5px;
    right: 0;
    margin-top: -5px;
    top: -41px
}


.header-area {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    top: 0;
    z-index: 9;
    padding-top: 28px
}

@media (max-width: 767px) {
    .header-area {
        padding-top: 0
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .header-area {
        padding-top: 0
    }
}

.header-area .main-header-area {
    padding: 0 150px
}

@media (max-width: 767px) {
    .header-area .main-header-area {
        padding: 10px 10px
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .header-area .main-header-area {
        padding: 10px 10px
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .header-area .main-header-area {
        padding: 0 20px
    }
}

@media (min-width: 1200px) and (max-width: 1500px) {
    .header-area .main-header-area {
        padding: 0 10px
    }
}

.header-area .main-header-area .logo-img {
    text-align: left
}

@media (max-width: 767px) {
    .header-area .main-header-area .logo-img {
        text-align: left
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .header-area .main-header-area .logo-img {
        text-align: left
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .header-area .main-header-area .logo-img {
        text-align: left
    }
}

@media (max-width: 767px) {
    .header-area .main-header-area .logo-img img {
        width: 70px
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .header-area .main-header-area .logo-img img {
        width: 70px
    }
}

.header-area .main-header-area .book_room {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
    -ms-flex-pack: flex-end
}

.header-area .main-header-area .book_room .socail_links ul {
    white-space: nowrap;
    /* Ngăn không cho các item ngắt sang hàng mới */
    overflow-x: auto;
    /* Thêm thanh cuộn nếu cần */
}

.header-area .main-header-area .book_room .socail_links ul li {
    display: inline-block;
    margin: 0 3px;
    /* Giảm khoảng cách giữa các icon nếu cần */
}

.header-area .main-header-area .book_room .socail_links ul li a {
    font-size: 12px;
    /* Giảm kích thước font của icon nếu đang sử dụng font icon */
    color: #A8A7A0;
}



.header-area .main-header-area .book_room .book_btn {
    margin-left: 20px
}

.header-area .main-header-area .book_room .book_btn a {
    background: #009DFF;
    padding: 12px 20px;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid transparent;
    color: #fff
}

.header-area .main-header-area .book_room .book_btn a:hover {
    background: #fff;
    color: #009DFF;
    border: 1px solid #009DFF
}

.header-area .main-header-area .main-menu {
    text-align: center;
    padding: 12px 0
}

.header-area .main-header-area .main-menu ul li {
    display: inline-block;
    position: relative;
    margin-right: 50px
}

@media (min-width: 992px) and (max-width: 1200px) {
    .header-area .main-header-area .main-menu ul li {
        margin-right: 20px
    }
}

@media (min-width: 1200px) and (max-width: 1500px) {
    .header-area .main-header-area .main-menu ul li {
        margin-right: 15px
    }
}

.header-area .main-header-area .main-menu ul li a {
    color: #fff;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 600;
    display: inline-block;
    padding: 0px 0px 10px 0px;
    font-family: "Raleway", sans-serif;
    position: relative;
    text-transform: capitalize
}

@media (min-width: 992px) and (max-width: 1200px) {
    .header-area .main-header-area .main-menu ul li a {
        font-size: 10px
    }
}

@media (min-width: 1200px) and (max-width: 1500px) {
    .header-area .main-header-area .main-menu ul li a {
        font-size: 10px
    }
}

.header-area .main-header-area .main-menu ul li a i {
    font-size: 9px
}

@media (max-width: 767px) {
    .header-area .main-header-area .main-menu ul li a i {
        display: none !important
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .header-area .main-header-area .main-menu ul li a i {
        display: none !important
    }
}

.header-area .main-header-area .main-menu ul li a::before {
    position: absolute;
    content: "";
    background: #fff;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    opacity: 0;
    transform: scaleX(0);
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -o-transition: .3s;
    transition: .3s
}

.header-area .main-header-area .main-menu ul li a:hover::before {
    opacity: 1;
    transform: scaleX(1)
}

.header-area .main-header-area .main-menu ul li a.active::before {
    opacity: 1;
    transform: scaleX(1)
}

.header-area .main-header-area .main-menu ul li a:hover {
    color: #fff
}

.header-area .main-header-area .main-menu ul li .submenu {
    position: absolute;
    left: 0;
    top: 140%;
    background: #fff;
    width: 200px;
    z-index: 2;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.02);
    opacity: 0;
    visibility: hidden;
    text-align: left;
    -webkit-transition: .6s;
    -moz-transition: .6s;
    -o-transition: .6s;
    transition: .6s
}

.header-area .main-header-area .main-menu ul li .submenu li {
    display: block
}

.header-area .main-header-area .main-menu ul li .submenu li a {
    padding: 10px 15px;
    position: inherit;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    display: block;
    color: #000
}

.header-area .main-header-area .main-menu ul li .submenu li a::before {
    display: none
}

.header-area .main-header-area .main-menu ul li .submenu li:hover a {
    color: #000
}

.header-area .main-header-area .main-menu ul li:hover>.submenu {
    opacity: 1;
    visibility: visible;
    top: 100%
}

.header-area .main-header-area .main-menu ul li:hover>a::before {
    opacity: 1;
    transform: scaleX(1)
}

.header-area .main-header-area .main-menu ul li:first-child a {
    padding-left: 0
}

.header-area .main-header-area.sticky {
    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 100%;
    top: -70px;
    left: 0;
    right: 0;
    z-index: 99;
    transform: translateY(70px);
    transition: transform 500ms ease, background 500ms ease;
    -webkit-transition: transform 500ms ease, background 500ms ease;
    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
    padding: 10px 150px;
    background: #000
}

@media (max-width: 767px) {
    .header-area .main-header-area.sticky {
        padding: 10px 10px
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .header-area .main-header-area.sticky {
        padding: 10px 10px
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .header-area .main-header-area.sticky {
        padding: 10px 20px
    }
}

@media (min-width: 1200px) and (max-width: 1500px) {
    .header-area .main-header-area.sticky {
        padding: 10px 20px
    }
}

.header-area .main-header-area.sticky .main-menu {
    padding: 0
}


/*  BANNER SLIDER 1 VÀ 2 */

.slider_bg_1 {
    background-image: url("https://imgur.com/hsaCuG6.png");
    height: 100vh;
    width: 767px;
    background-size: cover;
    background-repeat: no-repeat;

}

.slider_bg_2 {
    background-image: url("https://imgur.com/wkuLuGm.png");
    height: 100vh;
    width: 767px;
    background-size: cover;
    background-repeat: no-repeat;
}

/* banner 3 the loop */
.slider_bg_3 {
    background-image: url("https://imgur.com/P5cQkwo.png");
    height: 100vh;
    width: 767px;
    background-size: cover;
    background-repeat: no-repeat;
}

.slider_bg_4 {
    background-image: url("https://imgur.com/n3ok7Cu.png");
    height: 100vh;
    width: 767px;
    background-size: cover;
    background-repeat: no-repeat;
}


.slider_area .single_slider .slider_text h3 {
    color: #ffffff;
    font-family: "Raleway", sans-serif;
    font-size: 100px;
    text-transform: capitalize;
    letter-spacing: 2px;
    font-weight: 400px;
    margin-bottom: 4px
}

@media (max-width: 767px) {
    .slider_area .single_slider .slider_text h3 {
        font-size: 33px;
        letter-spacing: 3px
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .slider_area .single_slider .slider_text h3 {
        font-size: 30px
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .slider_area .single_slider .slider_text h3 {
        font-size: 35px;
        letter-spacing: 3px
    }
}

.slider_area .single_slider .slider_text p {
    font-size: 24px;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: 0;
    margin-top: 0
}

@media (min-width: 992px) and (max-width: 1200px) {
    .slider_area .single_slider .slider_text p {
        font-size: 16px
    }
}

@media (max-width: 767px) {
    .slider_area .single_slider .slider_text p {
        font-size: 16px
    }
}

.about_area {
    padding-top: 200px;
    padding-bottom: 100px
}

@media (max-width: 767px) {
    .about_area {
        padding-top: 40px;
        padding-bottom: 40px
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .about_area {
        padding-top: 80px;
        padding-bottom: 80px
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .about_area {
        padding-top: 100px;
        padding-bottom: 100px
    }
}

.about_area .about_info p {
    font-size: 16px;
    line-height: 28px;
    font-size: 16px;
    line-height: 28px;
    margin-top: 0;
    margin-bottom: 40px
}

@media (max-width: 767px) {
    .about_area .about_info a {
        margin-bottom: 20px
    }
}

.about_area .about_thumb {
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
    -ms-flex-pack: flex-end
}

@media (min-width: 768px) and (max-width: 991px) {
    .about_area .about_thumb {
        -webkit-justify-content: flex-start;
        -moz-justify-content: flex-start;
        -ms-justify-content: flex-start;
        justify-content: flex-start;
        -ms-flex-pack: flex-start;
        margin-top: 30px
    }
}

.about_area .about_thumb img {
    width: 100%
}

.about_area .about_thumb .img_2 {
    margin-top: 40px;
    margin-left: 10px
}

@media (min-width: 768px) and (max-width: 991px) {
    .about_area .about_thumb2 {
        margin-bottom: 30px
    }
}

.about_area .about_thumb2 img {
    width: 100%
}

.about_area .about_thumb2 .img_2 {
    margin-top: 40px;
    margin-left: 10px
}

/* Trang about  */
.about_bg_1 {
    background-image: url(https://imgur.com/ifpr96i.png)
}

.about_bg_2 {
    background-image: url(https://imgur.com/naqS4Cw.png)
}

.about_bg_3 {
    background-image: url(https://imgur.com/7PmKPam.png)
}

.about_bg_4 {
    background-image: url(https://imgur.com/05H9ycO.png)
}

.about_bg_5 {
    background-image: url(https://imgur.com/05H9ycO.png)
}

.breadcam_bg_7 {
    background-image: url(https://imgur.com/CnsyZ8Y.png)
}

.breadcam_bg_8 {
    background-image: url(https://imgur.com/mLDF2uF.png)
}



.about_bg_8 {
    background-image: url(https://imgur.com/Ar5NGYp.png)
}

.about_bg_9 {
    background-image: url(https://imgur.com/SLa7lzI.png)
}

.about_bg_10 {
    background-image: url(https://imgur.com/IdURgP4.png)
}

.about_bg_11 {
    background-image: url(https://imgur.com/QBPkRVY.png)
}

.about_bg_12 {
    background-image: url(https://imgur.com/Fbe8trV.png)
}

.about_bg_13 {
    background-image: url(https://imgur.com/C1lLfEH.png)
}

.about_bg_14 {
    background-image: url(https://imgur.com/exIqE3F.png)
}

.about_bg_15 {
    background-image: url(https://imgur.com/ZXO7qIO.png)
}

/* about khuyến mãi */
.about_bg_16 {
    background-image: url(https://imgur.com/gq2umpL.png)
}

.about_bg_17 {
    background-image: url(https://imgur.com/utbnpVr.png)
}

.about_bg_18 {
    background-image: url(https://imgur.com/r2F7VGQ.png)
}

.about_bg_19 {
    background-image: url(https://imgur.com/VparHeM.png)
}

.about_info_area {
    display: flex;
    margin-top: 50px
}

.about_active {
    width: 100%;
}

@media (min-width: 768px) and (max-width: 991px) {
    .about_info_area {
        padding-left: 0px
    }
}

@media (max-width: 767px) {
    .about_info_area {
        padding-left: 0px
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .about_info_area {
        padding-left: 0px
    }
}

@media (min-width: 1200px) and (max-width: 1500px) {
    .about_info_area {
        padding-left: 0px
    }
}

.about_info_area .single_slider {
    height: 750px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat
}

@media (max-width: 767px) {
    .about_info_area .single_slider {
        height: 400px
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .about_info_area .single_slider {
        height: 400px
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .about_info_area .single_slider {
        height: 500px
    }
}

.about_main_info {
    padding-top: 100px
}

@media (max-width: 767px) {
    .about_main_info {
        padding-top: 50px
    }
}

.about_main_info .single_about_info {
    margin-bottom: 43px
}

@media (max-width: 767px) {
    .about_main_info .single_about_info {
        margin-bottom: 30px
    }
}

.about_main_info .single_about_info h3 {
    font-size: 22px;
    color: #1F1F1F;
    margin-bottom: 0;
    margin-bottom: 10px;
    line-height: 32px
}

.about_main_info .single_about_info p {
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: #4D4D4D
}

@media (max-width: 767px) {
    .about_main_info .single_about_info p br {
        display: none
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .about_main_info .single_about_info p br {
        display: none
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .about_main_info .single_about_info p br {
        display: none
    }
}

@media (min-width: 1200px) and (max-width: 1500px) {
    .about_main_info .single_about_info p br {
        display: none
    }
}

.offers_area {
    padding-bottom: 10px
}

@media (max-width: 767px) {
    .offers_area {
        padding-bottom: 10px
    }
}

.offers_area.padding_top {
    padding-top: 50px
}

@media (max-width: 767px) {
    .offers_area.padding_top {
        padding-top: 40px
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .offers_area.padding_top {
        padding-top: 80px
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .offers_area.padding_top {
        padding-top: 80px
    }
}

@media (max-width: 767px) {
    .offers_area .single_offers {
        margin-bottom: 30px
    }
}

.offers_area .single_offers .about_thumb {
    overflow: hidden
}

.offers_area .single_offers .about_thumb img {
    width: 100%;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -o-transition: .3s;
    transition: .3s
}

.offers_area .single_offers h3 {
    font-size: 22px;
    font-weight: 400;
    color: #1F1F1F;
    margin-top: 32px;
}

@media (min-width: 768px) and (max-width: 991px) {
    .offers_area .single_offers h3 {
        font-size: 18px
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .offers_area .single_offers h3 br {
        display: none
    }
}

.offers_area .single_offers ul {
    margin-top: 17px;
    margin-bottom: 30px;
}

.offers_area .single_offers ul li {
    font-size: 16px;
    color: #4D4D4D;
    line-height: 28px;
    position: relative;
    z-index: 9;
    padding-left: 23px
}

.offers_area .single_offers ul li::before {
    position: absolute;
    content: "";
    width: 8px;
    height: 8px;
    background: #4D4D4D;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    border-radius: 50%
}

.offers_area .single_offers a {
    width: 100%;
    text-align: center
}

.offers_area .single_offers:hover .about_thumb img {
    width: 100%;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1)
}

/* backgroud video_Home */
.video_bg {
    background-image: url(https://imgur.com/fsNhhhr.png);
}

.video_area {
    padding: 250px 0;
    background-size: cover;
    background-position: center center
}

@media (max-width: 767px) {
    .video_area {
        padding: 100px 0
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .video_area {
        padding: 100px 0
    }
}

.video_area .video_area_inner span {
    font-size: 14px;
    color: #fff
}

.video_area .video_area_inner h3 {
    font-size: 46px;
    color: #fff;
    line-height: 56px;
    font-weight: 400;
    margin-top: 12px;
    margin-bottom: 28px
}

@media (max-width: 767px) {
    .video_area .video_area_inner h3 {
        font-size: 30px
    }
}

.video_area .video_area_inner a {
    width: 60px;
    height: 60px;
    background: #fff;
    line-height: 60px;
    font-size: 15px;
    color: #009DFF;
    display: inline-block;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%
}

.video_area .video_area_inner a i {
    position: relative;
    left: 2px
}

.features_room {
    padding-top: 83px;
    display: block;
    overflow: hidden
}

@media (max-width: 767px) {
    .features_room {
        padding-top: 40px
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .features_room {
        padding-top: 0
    }
}

.features_room .rooms_here .single_rooms {
    position: relative;
    width: 50%;
    float: left
}

@media (max-width: 767px) {
    .features_room .rooms_here .single_rooms {
        width: 100%;
        margin-bottom: 10px
    }
}

.features_room .rooms_here .single_rooms::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: #ffffff;
    background: -moz-linear-gradient(top, #fff 0%, #000 77%);
    background: -webkit-linear-gradient(top, #fff 0%, #000 77%);
    background: linear-gradient(to bottom, #fff 0%, #000 77%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#000000', GradientType=0);
    z-index: 1;
    opacity: .5
}

.features_room .rooms_here .single_rooms .room_thumb {
    position: relative;
    overflow: hidden
}

.features_room .rooms_here .single_rooms .room_thumb img {
    width: 100%;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -o-transition: .5s;
    transition: .5s
}

.features_room .rooms_here .single_rooms .room_thumb .room_heading {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    padding: 30px 60px 37px 60px
}

@media (max-width: 767px) {
    .features_room .rooms_here .single_rooms .room_thumb .room_heading {
        padding: 20px
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .features_room .rooms_here .single_rooms .room_thumb .room_heading {
        padding: 20px
    }
}

.features_room .rooms_here .single_rooms .room_thumb .room_heading span {
    font-size: 14px;
    color: #FFFFFF;
    margin-bottom: 9px;
    display: block;
    position: relative;
    z-index: 8
}

.features_room .rooms_here .single_rooms .room_thumb .room_heading h3 {
    font-size: 30px;
    color: #fff;
    position: relative;
    z-index: 8
}

@media (max-width: 767px) {
    .features_room .rooms_here .single_rooms .room_thumb .room_heading h3 {
        font-size: 20px
    }
}

.features_room .rooms_here .single_rooms .room_thumb .room_heading a {
    color: #fff;
    text-transform: capitalize;
    font-weight: 600;
    position: relative;
    z-index: 8;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    transform: translateY(-40px);
    opacity: 0;
    visibility: hidden
}

.features_room .rooms_here .single_rooms .room_thumb .room_heading a:hover {
    color: #009DFF
}

.features_room .rooms_here .single_rooms:hover img {
    width: 100%;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1)
}

.features_room .rooms_here .single_rooms:hover .room_heading a {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
    visibility: visible
}

.forQuery {
    padding-top: 150px;
    padding-bottom: 100px
}

@media (max-width: 767px) {
    .forQuery {
        padding: 60px 0
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .forQuery {
        padding: 100px 0
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .forQuery {
        padding: 100px 0
    }
}

@media (min-width: 1200px) and (max-width: 1500px) {
    .forQuery {
        padding: 100px 0
    }
}

.forQuery .Query_border {
    border: 1px solid #BABABA;
    padding: 38px 50px
}

@media (max-width: 767px) {
    .forQuery .Query_border {
        padding: 20px 20px
    }
}

.forQuery .Query_border .Query_text {
    text-align: left
}

.forQuery .Query_border p {
    font-size: 30px;
    color: #1F1F1F;
    font-weight: 400;
    margin-bottom: 0
}

@media (max-width: 767px) {
    .forQuery .Query_border p {
        margin-bottom: 20px;
        font-size: 18px;
        text-align: center
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .forQuery .Query_border p {
        font-size: 18px
    }
}

.forQuery .Query_border .phone_num {
    text-align: right
}

@media (max-width: 767px) {
    .forQuery .Query_border .phone_num {
        text-align: center
    }
}

.forQuery .Query_border .phone_num a {
    background: #009DFF;
    color: #fff;
    padding: 12px 53px;
    border-radius: 30px;
    display: inline-block;
    font-size: 18px;
    border: 1px solid transparent
}

.forQuery .Query_border .phone_num a:hover {
    color: #009DFF;
    border: 1px solid #009DFF;
    background: #fff
}

.instragram_area {
    display: block;
    overflow: hidden
}

.instragram_area .single_instagram {
    width: 20%;
    float: left;
    position: relative;
    overflow: hidden
}

@media (max-width: 767px) {
    .instragram_area .single_instagram {
        width: 100%
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .instragram_area .single_instagram {
        width: 50%
    }
}

.instragram_area .single_instagram img {
    width: 100%;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -o-transition: .5s;
    transition: .5s
}

.instragram_area .single_instagram .ovrelay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    -webkit-transform: translateX(-80%);
    -moz-transform: translateX(-80%);
    -ms-transform: translateX(-80%);
    transform: translateX(-80%);
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    opacity: 0;
    visibility: hidden
}

.instragram_area .single_instagram .ovrelay a {
    color: #fff;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    text-align: center;
    font-size: 34px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.instragram_area .single_instagram:hover .ovrelay {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1;
    visibility: visible
}

.instragram_area .single_instagram:hover img {
    -webkit-transform: scaleX(1.1);
    -moz-transform: scaleX(1.1);
    -ms-transform: scaleX(1.1);
    transform: scaleX(1.1)
}

#test-form .white-popup-block .popup_inner .gj-datepicker span {
    color: red
}

#test-form .white-popup-block input {
    width: 100%;
    height: 50px
}

.gj-datepicker input {
    width: 100%;
    height: 50px;
    border: 1px solid #ddd;
    padding: 17px;
    font-size: 12px;
    color: #919191;
    margin-bottom: 20px
}

.gj-datepicker-md [role="right-icon"] {
    position: absolute;
    right: 0px;
    top: 0px;
    font-size: 14px;
    color: #919191;
    margin-right: 15px;
    top: 16px
}

.gj-picker-md {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: .04em;
    line-height: 1;
    color: rgba(0, 0, 0, 0.87);
    padding: 10px;
    padding: 20px;
    border: 1px solid #E0E0E0
}

.footer {
    background: #000
}

.footer .footer_top {
    padding-top: 145px;
    padding-bottom: 129px
}

@media (max-width: 767px) {
    .footer .footer_top {
        padding-top: 60px;
        padding-bottom: 30px
    }
}

@media (max-width: 767px) {
    .footer .footer_top .footer_widget {
        margin-bottom: 30px
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .footer .footer_top .footer_widget {
        margin-bottom: 30px
    }
}

.footer .footer_top .footer_widget .footer_title {
    font-size: 22px;
    font-weight: 400;
    color: #fff;
    text-transform: capitalize;
    margin-bottom: 40px
}

@media (max-width: 767px) {
    .footer .footer_top .footer_widget .footer_title {
        margin-bottom: 20px
    }
}

.footer .footer_top .footer_widget p.footer_text {
    font-size: 16px;
    color: #BABABA;
    margin-bottom: 23px;
    font-weight: 400;
    line-height: 28px
}

.footer .footer_top .footer_widget ul li a {
    font-size: 16px;
    color: #BABABA;
    line-height: 42px
}

.footer .footer_top .footer_widget ul li a:hover {
    color: #009DFF
}

.footer .footer_top .footer_widget .newsletter_form {
    position: relative;
    margin-bottom: 20px
}

.footer .footer_top .footer_widget .newsletter_form input {
    width: 100%;
    height: 45px;
    background: #fff;
    padding-left: 20px;
    font-size: 16px;
    color: #000;
    border: none
}

.footer .footer_top .footer_widget .newsletter_form input::placeholder {
    font-size: 16px;
    color: #919191
}

.footer .footer_top .footer_widget .newsletter_form button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    border: none;
    font-size: 14px;
    color: #fff;
    background: #009DFF;
    padding: 10px;
    padding: 0 22px;
    cursor: pointer
}

.footer .footer_top .footer_widget .newsletter_text {
    font-size: 16px;
    color: #BABABA
}

.footer .copy-right_text {
    padding-bottom: 30px
}

.footer .copy-right_text .footer_border {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-bottom: 30px
}

.footer .copy-right_text .copy_right {
    font-size: 16px;
    color: #919191;
    margin-bottom: 0;
    font-weight: 400
}

@media (max-width: 767px) {
    .footer .copy-right_text .copy_right {
        font-size: 14px
    }
}

.footer .copy-right_text .copy_right a {
    color: #009DFF
}

.footer .copy-right_text .socail_links {
    text-align: right
}

@media (max-width: 767px) {
    .footer .copy-right_text .socail_links {
        text-align: center;
        margin-top: 30px
    }
}

.footer .copy-right_text .socail_links ul li {
    display: inline-block
}

.footer .copy-right_text .socail_links ul li a {
    color: #A8A7A0;
    margin: 0 10px;
    font-size: 15px
}

.footer .copy-right_text .socail_links ul li a:hover {
    color: #fff
}

/* HEADER BANNER */
/* banner about */
.breadcam_bg {
    background-image: url(https://imgur.com/ohVaPRY.png)
}

/* ẢNH BANNER ROOMS */
.breadcam_bg_1 {
    background-image: url(https://imgur.com/soiQMDn.png)
}

/* banner blog */
.breadcam_bg_3 {
    background-image: url(https://imgur.com/Rb1m3dE.png)
}

/* banner restaurant */
.breadcam_bg_4 {
    background-image: url(https://imgur.com/wUwvXKD.png)
}

/* banner contact */
.breadcam_bg_5 {
    background-image: url(https://imgur.com/QuyWBuD.png)
}

.breadcam_bg_6 {
    background-image: url(https://imgur.com/QuyWBuD.png)
}

.breadcam_bg_13 {
    background-image: url(https://imgur.com/eiTxD9l.png)
}

.nav-tabs {
    justify-content: center;
    /* Căn giữa các tab */
}

.nav-link.active {
    color: white !important;
    /* Màu chữ cho tab đang active */
    background-color: orange !important;
    /* Màu nền cho tab đang active */
}

.food-menu .h4,
.food-menu h3 {
    color: white !important;
    /* Đặt màu chữ là màu trắng */
}

.nav-link {
    color: white !important;
}

.bradcam_area {
    padding: 170px;
    background-size: cover;
    background-position: center center;
    text-align: center;
    padding: 269px 0 159px 0
}

@media (max-width: 767px) {
    .bradcam_area {
        padding: 150px 0
    }
}

.bradcam_area h3 {
    font-size: 60px;
    color: #fff;
    font-weight: 400;
    margin-bottom: 0;
    text-transform: capitalize
}

@media (max-width: 767px) {
    .bradcam_area h3 {
        font-size: 30px
    }
}

.popup_box {
    background: #fff;
    display: inline-block;
    z-index: 9;
    width: 681px;
    padding: 60px 40px
}

.popup_box h3 {
    text-align: center;
    font-size: 22px;
    color: #1F1F1F;
    margin-bottom: 46px
}

.popup_box .boxed-btn3 {
    width: 100%;
    text-transform: capitalize
}

.popup_box .nice-select {
    -webkit-tap-highlight-color: transparent;
    background-color: #fff;
    border: solid 1px #E2E2E2;
    box-sizing: border-box;
    clear: both;
    cursor: pointer;
    display: block;
    float: left;
    font-family: "Raleway", sans-serif;
    font-weight: normal;
    width: 100% !important;
    line-height: 50px;
    outline: none;
    padding-left: 18px;
    padding-right: 30px;
    position: relative;
    text-align: left !important;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
    width: auto;
    border-radius: 0;
    margin-bottom: 30px;
    height: 50px !important;
    font-size: 16px;
    font-weight: 400;
    color: #919191
}

.popup_box .nice-select::after {
    content: "\f0d7";
    display: block;
    height: 5px;
    margin-top: -5px;
    pointer-events: none;
    position: absolute;
    right: 17px;
    top: 3px;
    transition: all 0.15s ease-in-out;
    width: 5px;
    font-family: fontawesome;
    color: #919191;
    font-size: 15px
}

.popup_box .nice-select.open .list {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: scale(1) translateY(0);
    -ms-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    height: 200px;
    overflow-y: scroll
}

.popup_box .nice-select.list {
    height: 200px;
    overflow-y: scroll
}

#test-form {
    display: inline-block;
    margin: auto;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

#test-form .mfp-close-btn-in .mfp-close {
    color: #333;
    display: none !important
}

#test-form button.mfp-close {
    display: none !important
}

.mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: #000000;
    opacity: .7
}

.dropdown .dropdown-menu {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s
}

.contact-info {
    margin-bottom: 25px
}

.contact-info__icon {
    margin-right: 20px
}

.contact-info__icon i,
.contact-info__icon span {
    color: #8f9195;
    font-size: 27px
}

.contact-info .media-body h3 {
    font-size: 16px;
    margin-bottom: 0;
    font-size: 16px;
    color: #2a2a2a
}

.contact-info .media-body h3 a:hover {
    color: #ff5e13
}

.contact-info .media-body p {
    color: #8a8a8a
}

.contact-title {
    font-size: 27px;
    font-weight: 600;
    margin-bottom: 20px
}

.form-contact label {
    font-size: 14px
}

.form-contact .form-group {
    margin-bottom: 30px
}

.form-contact .form-control {
    border: 1px solid #e5e6e9;
    border-radius: 0px;
    height: 48px;
    padding-left: 18px;
    font-size: 13px;
    background: transparent
}

.form-contact .form-control:focus {
    outline: 0;
    box-shadow: none
}

.blog-container {
    padding-top: 50px;
    padding-bottom: 20px;
    width: 60%;
    margin: auto;
    text-align: center;
}

.description,
.source {
    margin: 10px 0;
}

.form-contact .form-control::placeholder {
    font-weight: 300;
    color: #999999
}

.form-contact textarea {
    border-radius: 0px;
    height: 100% !important
}

.modal-message .modal-dialog {
    position: absolute;
    top: 36%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) !important;
    margin: 0px;
    max-width: 500px;
    width: 100%
}

.modal-message .modal-dialog .modal-content .modal-header {
    text-align: center;
    display: block;
    border-bottom: none;
    padding-top: 50px;
    padding-bottom: 50px
}

.modal-message .modal-dialog .modal-content .modal-header .close {
    position: absolute;
    right: -15px;
    top: -15px;
    padding: 0px;
    color: #fff;
    opacity: 1;
    cursor: pointer
}

.modal-message .modal-dialog .modal-content .modal-header h2 {
    display: block;
    text-align: center;
    padding-bottom: 10px
}

.modal-message .modal-dialog .modal-content .modal-header p {
    display: block
}

.contact-section {
    padding: 130px 0 100px
}

@media (max-width: 991px) {
    .contact-section {
        padding: 70px 0 40px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .contact-section {
        padding: 80px 0 50px
    }
}

.-section .btn_2 {
    background-color: #191d34;
    padding: 18px 60px;
    border-radius: 50px;
    margin-top: 0
}

.contact-section .btn_2:hover {
    background-color: #f2f2f2
}

.sample-text-area {
    background: #fff;
    padding: 100px 0 20px 0
}

.text-heading {
    margin-bottom: 5px;
    font-size: 24px
}

b,
sup,
sub,
u,
del {
    color: #ff5e13
}

h1 {
    font-size: 36px
}

h2 {
    font-size: 30px
}

h3 {
    font-size: 24px
}

h4 {
    font-size: 18px
}

h5 {
    font-size: 16px
}

h6 {
    font-size: 14px
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.2em
}

.typography h1,
.typography h2,
.typography h3,
.typography h4,
.typography h5,
.typography h6 {
    color: #828bb2
}

.button-area {
    background: #fff
}

.button-area .border-top-generic {
    padding: 70px 15px;
    border-top: 1px dotted #eee
}

.button-group-area .genric-btn {
    margin-right: 10px;
    margin-top: 10px
}

.button-group-area .genric-btn:last-child {
    margin-right: 0
}

.genric-btn {
    display: inline-block;
    outline: none;
    line-height: 40px;
    padding: 0 30px;
    font-size: .8em;
    text-align: center;
    text-decoration: none;
    font-weight: 500;
    cursor: pointer;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.genric-btn:focus {
    outline: none
}

.genric-btn.e-large {
    padding: 0 40px;
    line-height: 50px
}

.genric-btn.large {
    line-height: 45px
}

.genric-btn.medium {
    line-height: 30px
}

.genric-btn.small {
    line-height: 25px
}

.genric-btn.radius {
    border-radius: 3px
}

.genric-btn.circle {
    border-radius: 20px
}

.genric-btn.arrow {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.genric-btn.arrow span {
    margin-left: 10px
}

.genric-btn.default {
    color: #415094;
    background: #f9f9ff;
    border: 1px solid transparent
}

.genric-btn.default:hover {
    border: 1px solid #f9f9ff;
    background: #fff
}

.blog_area a:hover,
.blog_area a:hover {
    background: -webkit-linear-gradient(131deg, #009DFF 0%, #009DFF 99%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-decoration: none;
    -webkit-transition: .5s;
    transition: .5s
}

.single-blog {
    overflow: hidden;
    margin-bottom: 30px
}

.single-blog:hover {
    box-shadow: 0px 10px 20px 0px rgba(42, 34, 123, 0.1)
}

.single-blog .thumb {
    overflow: hidden;
    position: relative
}

.single-blog .thumb:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0;
    -webkit-transition: .5s;
    transition: .5s
}

.single-blog h4 {
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 34px;
    margin-bottom: 25px
}

.single-blog a {
    font-size: 20px;
    font-weight: 600
}

.single-blog .date {
    color: #666666;
    text-align: left;
    display: inline-block;
    font-size: 13px;
    font-weight: 300
}

.single-blog .tag {
    text-align: left;
    display: inline-block;
    font-size: 13px;
    font-weight: 300;
    margin-right: 22px;
    position: relative
}

.single-blog .tag:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 10px;
    background: #acacac;
    right: -12px;
    top: 7px
}

@media (max-width: 1199px) {
    .single-blog .tag {
        margin-right: 8px
    }

    .single-blog .tag:after {
        display: none
    }
}

.single-blog .likes {
    margin-right: 16px
}

@media (max-width: 800px) {
    .single-blog {
        margin-bottom: 30px
    }
}

.single-blog .single-blog-content {
    padding: 30px
}

.single-blog .single-blog-content .meta-bottom p {
    font-size: 13px;
    font-weight: 300
}

.single-blog .single-blog-content .meta-bottom i {
    color: #fdcb9e;
    font-size: 13px;
    margin-right: 7px
}

@media (max-width: 1199px) {
    .single-blog .single-blog-content {
        padding: 15px
    }
}

.single-blog:hover .thumb:after {
    opacity: .7;
    -webkit-transition: .5s;
    transition: .5s
}

@media (max-width: 1199px) {
    .single-blog h4 {
        transition: all 300ms linear 0s;
        border-bottom: 1px solid #dfdfdf;
        padding-bottom: 14px;
        margin-bottom: 12px
    }

    .single-blog h4 a {
        font-size: 18px
    }
}

.full_image.single-blog {
    position: relative
}

.full_image.single-blog .single-blog-content {
    position: absolute;
    left: 35px;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .5s;
    transition: .5s
}

@media (min-width: 992px) {
    .full_image.single-blog .single-blog-content {
        bottom: 100px
    }
}

.full_image.single-blog h4 {
    -webkit-transition: .5s;
    transition: .5s;
    border-bottom: none;
    padding-bottom: 5px
}

.full_image.single-blog a {
    font-size: 20px;
    font-weight: 600
}

.full_image.single-blog .date {
    color: #fff
}

.full_image.single-blog:hover .single-blog-content {
    opacity: 1;
    visibility: visible;
    -webkit-transition: .5s;
    transition: .5s
}

.l_blog_item .l_blog_text .date {
    margin-top: 24px;
    margin-bottom: 15px
}

.l_blog_item .l_blog_text .date a {
    font-size: 12px
}

.l_blog_item .l_blog_text h4 {
    font-size: 18px;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 0px;
    padding-bottom: 20px;
    -webkit-transition: .5s;
    transition: .5s
}

.l_blog_item .l_blog_text p {
    margin-bottom: 0px;
    padding-top: 20px
}

.causes_slider .owl-dots {
    text-align: center;
    margin-top: 80px
}

.causes_slider .owl-dots .owl-dot {
    height: 14px;
    width: 14px;
    background: #eeeeee;
    display: inline-block;
    margin-right: 7px
}

.causes_slider .owl-dots .owl-dot:last-child {
    margin-right: 0px
}

.causes_item {
    background: #fff
}

.causes_item .causes_img {
    position: relative
}

.causes_item .causes_img .c_parcent {
    position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0px;
    height: 3px;
    background: rgba(255, 255, 255, 0.5)
}

.causes_item .causes_img .c_parcent span {
    width: 70%;
    height: 3px;
    position: absolute;
    left: 0px;
    bottom: 0px
}

.causes_item .causes_img .c_parcent span:before {
    content: "75%";
    position: absolute;
    right: -10px;
    bottom: 0px;
    color: #fff;
    padding: 0px 5px
}

.causes_item .causes_text {
    padding: 30px 35px 40px 30px
}

.causes_item .causes_text h4 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
    cursor: pointer
}

.causes_item .causes_text p {
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    margin-bottom: 0px
}

.causes_item .causes_bottom a {
    width: 50%;
    border: 1px solid;
    text-align: center;
    float: left;
    line-height: 50px;
    color: #fff;
    font-size: 14px;
    font-weight: 500
}

.causes_item .causes_bottom a+a {
    border-color: #eeeeee;
    background: #fff;
    font-size: 14px
}

.latest_blog_area {
    background: #f9f9ff
}

.single-recent-blog-post {
    margin-bottom: 30px
}

.single-recent-blog-post .thumb {
    overflow: hidden
}

.single-recent-blog-post .thumb img {
    transition: all 0.7s linear
}

.single-recent-blog-post .details {
    padding-top: 30px
}

.single-recent-blog-post .details .sec_h4 {
    line-height: 24px;
    padding: 10px 0px 13px;
    transition: all 0.3s linear
}

.single-recent-blog-post .date {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400
}

.single-recent-blog-post:hover img {
    transform: scale(1.23) rotate(10deg)
}

.tags .tag_btn {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    border: 1px solid #eeeeee;
    display: inline-block;
    padding: 1px 18px;
    text-align: center
}

.tags .tag_btn+.tag_btn {
    margin-left: 2px
}

.blog_categorie_area {
    padding-top: 30px;
    padding-bottom: 30px
}

@media (min-width: 900px) {
    .blog_categorie_area {
        padding-top: 80px;
        padding-bottom: 80px
    }
}

@media (min-width: 1100px) {
    .blog_categorie_area {
        padding-top: 120px;
        padding-bottom: 120px
    }
}

.categories_post {
    position: relative;
    text-align: center;
    cursor: pointer
}

.categories_post img {
    max-width: 100%
}

.categories_post .categories_details {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    background: rgba(34, 34, 34, 0.75);
    color: #fff;
    transition: all 0.3s linear;
    display: flex;
    align-items: center;
    justify-content: center
}

.categories_post .categories_details h5 {
    margin-bottom: 0px;
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;
    color: #fff;
    position: relative
}

.categories_post .categories_details p {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 0px
}

.categories_post .categories_details .border_line {
    margin: 10px 0px;
    background: #fff;
    width: 100%;
    height: 1px
}

.categories_post:hover .categories_details {
    background: rgba(222, 99, 32, 0.85)
}

.blog_item {
    margin-bottom: 50px
}

.blog_details {
    padding: 30px 0 20px 10px;
    box-shadow: 0px 10px 20px 0px rgba(221, 221, 221, 0.3)
}

@media (min-width: 768px) {
    .blog_details {
        padding: 60px 30px 35px 35px
    }
}

.blog_details p {
    margin-bottom: 30px
}

.blog_details a {
    color: #ff8b23
}

.blog_details a:hover {
    color: #009DFF
}

.blog_details h2 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px
}

@media (min-width: 768px) {
    .blog_details h2 {
        font-size: 24px;
        margin-bottom: 15px
    }
}

.blog-info-link li {
    float: left;
    font-size: 14px
}

.blog-info-link li a {
    color: #999999
}

.blog-info-link li i,
.blog-info-link li span {
    font-size: 13px;
    margin-right: 5px
}

.blog-info-link li::after {
    content: "|";
    padding-left: 10px;
    padding-right: 10px
}

.blog-info-link li:last-child::after {
    display: none
}

.blog-info-link::after {
    content: "";
    display: block;
    clear: both;
    display: table
}

.blog_item_img {
    position: relative
}

.blog_item_img .blog_item_date {
    position: absolute;
    bottom: -10px;
    left: 10px;
    display: block;
    color: #fff;
    background-color: #009DFF;
    padding: 8px 15px;
    border-radius: 5px
}

@media (min-width: 768px) {
    .blog_item_img .blog_item_date {
        bottom: -20px;
        left: 40px;
        padding: 13px 30px
    }
}

.blog_item_img .blog_item_date h3 {
    font-size: 22px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0;
    line-height: 1.2
}

@media (min-width: 768px) {
    .blog_item_img .blog_item_date h3 {
        font-size: 30px
    }
}

.blog_item_img .blog_item_date p {
    font-size: 18px;
    margin-bottom: 0;
    color: #fff
}

@media (min-width: 768px) {
    .blog_item_img .blog_item_date p {
        font-size: 18px
    }
}

.blog_right_sidebar .widget_title {
    font-size: 20px;
    margin-bottom: 40px
}

.blog_right_sidebar .widget_title::after {
    content: "";
    display: block;
    padding-top: 15px;
    border-bottom: 1px solid #f0e9ff
}

.blog_right_sidebar .single_sidebar_widget {
    background: #fbf9ff;
    padding: 30px;
    margin-bottom: 30px
}

.blog_right_sidebar .single_sidebar_widget .btn_1 {
    margin-top: 0px
}

.blog_right_sidebar .search_widget .form-control {
    height: 50px;
    border-color: #f0e9ff;
    font-size: 13px;
    color: #999999;
    padding-left: 20px;
    border-radius: 0;
    border-right: 0
}

.blog_right_sidebar .search_widget .form-control::placeholder {
    color: #999999
}

.blog_right_sidebar .search_widget .form-control:focus {
    border-color: #f0e9ff;
    outline: 0;
    box-shadow: none
}

.blog_right_sidebar .search_widget .input-group button {
    background: #fff;
    border-left: 0;
    border: 1px solid #f0e9ff;
    padding: 4px 15px;
    border-left: 0
}

.blog_right_sidebar .search_widget .input-group button i,
.blog_right_sidebar .search_widget .input-group button span {
    font-size: 14px;
    color: #999999
}

.blog_right_sidebar .newsletter_widget .form-control {
    height: 50px;
    border-color: #f0e9ff;
    font-size: 13px;
    color: #999999;
    padding-left: 20px;
    border-radius: 0
}

.blog_right_sidebar .newsletter_widget .form-control::placeholder {
    color: #999999
}

.blog_right_sidebar .newsletter_widget .form-control:focus {
    border-color: #f0e9ff;
    outline: 0;
    box-shadow: none
}

.blog_right_sidebar .newsletter_widget .input-group button {
    background: #fff;
    border-left: 0;
    border: 1px solid #f0e9ff;
    padding: 4px 15px;
    border-left: 0
}

.blog_right_sidebar .newsletter_widget .input-group button i,
.blog_right_sidebar .newsletter_widget .input-group button span {
    font-size: 14px;
    color: #999999
}

.blog_right_sidebar .post_category_widget .cat-list li {
    border-bottom: 1px solid #f0e9ff;
    transition: all 0.3s ease 0s;
    padding-bottom: 12px
}

.blog_right_sidebar .post_category_widget .cat-list li:last-child {
    border-bottom: 0
}

.blog_right_sidebar .post_category_widget .cat-list li a {
    font-size: 14px;
    line-height: 20px;
    color: #888888
}

.blog_right_sidebar .post_category_widget .cat-list li a p {
    margin-bottom: 0px
}

.blog_right_sidebar .post_category_widget .cat-list li+li {
    padding-top: 15px
}

.blog_right_sidebar .popular_post_widget .post_item .media-body {
    justify-content: center;
    align-self: center;
    padding-left: 20px
}

.blog_right_sidebar .popular_post_widget .post_item .media-body h3 {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 6px;
    transition: all 0.3s linear
}

.blog_right_sidebar .popular_post_widget .post_item .media-body a:hover {
    color: #fff
}

.blog_right_sidebar .popular_post_widget .post_item .media-body p {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 0px
}

.blog_right_sidebar .popular_post_widget .post_item+.post_item {
    margin-top: 20px
}

.blog_right_sidebar .tag_cloud_widget ul li {
    display: inline-block
}

.blog_right_sidebar .tag_cloud_widget ul li a {
    display: inline-block;
    border: 1px solid #eeeeee;
    background: #fff;
    padding: 4px 20px;
    margin-bottom: 8px;
    margin-right: 3px;
    transition: all 0.3s ease 0s;
    color: #888888;
    font-size: 13px
}

.blog_right_sidebar .tag_cloud_widget ul li a:hover {
    background: #009DFF;
    color: #fff !important;
    -webkit-text-fill-color: #fff;
    text-decoration: none;
    -webkit-transition: 0.5s;
    transition: 0.5s
}

.blog_right_sidebar .instagram_feeds .instagram_row {
    display: flex;
    margin-right: -6px;
    margin-left: -6px
}

.blog_right_sidebar .instagram_feeds .instagram_row li {
    width: 33.33%;
    float: left;
    padding-right: 6px;
    padding-left: 6px;
    margin-bottom: 15px
}

.blog_right_sidebar .br {
    width: 100%;
    height: 1px;
    background: #eee;
    margin: 30px 0px
}

.blog-pagination {
    margin-top: 80px
}

.blog-pagination .page-link {
    font-size: 14px;
    position: relative;
    display: block;
    padding: 0;
    text-align: center;
    margin-left: -1px;
    line-height: 45px;
    width: 45px;
    height: 45px;
    border-radius: 0 !important;
    color: #8a8a8a;
    border: 1px solid #f0e9ff;
    margin-right: 10px
}

.blog-pagination .page-link i,
.blog-pagination .page-link span {
    font-size: 13px
}

.blog-pagination .page-item.active .page-link {
    background-color: #fbf9ff;
    border-color: #f0e9ff;
    color: #888888
}

.blog-pagination .page-item:last-child .page-link {
    margin-right: 0
}

.single-post-area .blog_details {
    box-shadow: none;
    padding: 0
}

.single-post-area .social-links {
    padding-top: 10px
}

.single-post-area .social-links li {
    display: inline-block;
    margin-bottom: 10px
}

.single-post-area .social-links li a {
    color: #cccccc;
    padding: 7px;
    font-size: 14px;
    transition: all 0.2s linear
}

.single-post-area .blog_details {
    padding-top: 26px
}

.single-post-area .blog_details p {
    margin-bottom: 20px;
    font-size: 15px
}

.single-post-area .quote-wrapper {
    background: rgba(130, 139, 178, 0.1);
    padding: 15px;
    line-height: 1.733;
    color: #888888;
    font-style: italic;
    margin-top: 25px;
    margin-bottom: 25px
}

@media (min-width: 768px) {
    .single-post-area .quote-wrapper {
        padding: 30px
    }
}

.single-post-area .quotes {
    background: #fff;
    padding: 15px 15px 15px 20px;
    border-left: 2px solid
}

@media (min-width: 768px) {
    .single-post-area .quotes {
        padding: 25px 25px 25px 30px
    }
}

.single-post-area .arrow {
    position: absolute
}

.single-post-area .arrow .lnr {
    font-size: 20px;
    font-weight: 600
}

.single-post-area .thumb .overlay-bg {
    background: rgba(0, 0, 0, 0.8)
}

.single-post-area .navigation-top {
    padding-top: 15px;
    border-top: 1px solid #f0e9ff
}

.single-post-area .navigation-top p {
    margin-bottom: 0
}

.single-post-area .navigation-top .like-info {
    font-size: 14px
}

.single-post-area .navigation-top .like-info i,
.single-post-area .navigation-top .like-info span {
    font-size: 16px;
    margin-right: 5px
}

.single-post-area .navigation-top .comment-count {
    font-size: 14px
}

.single-post-area .navigation-top .comment-count i,
.single-post-area .navigation-top .comment-count span {
    font-size: 16px;
    margin-right: 5px
}

.single-post-area .navigation-top .social-icons li {
    display: inline-block;
    margin-right: 15px
}

.single-post-area .navigation-top .social-icons li:last-child {
    margin: 0
}

.single-post-area .navigation-top .social-icons li i,
.single-post-area .navigation-top .social-icons li span {
    font-size: 14px;
    color: #999999
}

.single-post-area .blog-author {
    padding: 40px 30px;
    background: #fbf9ff;
    margin-top: 50px
}

@media (max-width: 600px) {
    .single-post-area .blog-author {
        padding: 20px 8px
    }
}

.single-post-area .blog-author img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin-right: 30px
}

@media (max-width: 600px) {
    .single-post-area .blog-author img {
        margin-right: 15px;
        width: 45px;
        height: 45px
    }
}

.single-post-area .blog-author a {
    display: inline-block
}

.single-post-area .blog-author a:hover {
    color: #009DFF
}

.single-post-area .blog-author p {
    margin-bottom: 0;
    font-size: 15px
}

.single-post-area .blog-author h4 {
    font-size: 16px
}

.single-post-area .navigation-area {
    border-bottom: 1px solid #eee;
    padding-bottom: 30px;
    margin-top: 55px
}

.single-post-area .navigation-area p {
    margin-bottom: 0px
}

.single-post-area .navigation-area h4 {
    font-size: 18px;
    line-height: 25px
}

.single-post-area .navigation-area .nav-left {
    text-align: left
}

.single-post-area .navigation-area .nav-left .thumb {
    margin-right: 20px;
    background: #000
}

.single-post-area .navigation-area .nav-left .thumb img {
    -webkit-transition: .5s;
    transition: .5s
}

.single-post-area .navigation-area .nav-left .lnr {
    margin-left: 20px;
    opacity: 0;
    -webkit-transition: .5s;
    transition: .5s
}

.single-post-area .navigation-area .nav-left:hover .lnr {
    opacity: 1
}

.single-post-area .navigation-area .nav-left:hover .thumb img {
    opacity: .5
}

@media (max-width: 767px) {
    .single-post-area .navigation-area .nav-left {
        margin-bottom: 30px
    }
}

.single-post-area .navigation-area .nav-right {
    text-align: right
}

.single-post-area .navigation-area .nav-right .thumb {
    margin-left: 20px;
    background: #000
}

.single-post-area .navigation-area .nav-right .thumb img {
    -webkit-transition: .5s;
    transition: .5s
}

.single-post-area .navigation-area .nav-right .lnr {
    margin-right: 20px;
    opacity: 0;
    -webkit-transition: .5s;
    transition: .5s
}

.single-post-area .navigation-area .nav-right:hover .lnr {
    opacity: 1
}

.single-post-area .navigation-area .nav-right:hover .thumb img {
    opacity: .5
}

@media (max-width: 991px) {
    .single-post-area .sidebar-widgets {
        padding-bottom: 0px
    }
}

.comments-area {
    background: transparent;
    border-top: 1px solid #eee;
    padding: 45px 0;
    margin-top: 50px
}

@media (max-width: 414px) {
    .comments-area {
        padding: 50px 8px
    }
}

.comments-area h4 {
    margin-bottom: 35px;
    font-size: 18px
}

.comments-area h5 {
    font-size: 16px;
    margin-bottom: 0px
}

.comments-area .comment-list {
    padding-bottom: 48px
}

.comments-area .comment-list:last-child {
    padding-bottom: 0px
}

.comments-area .comment-list.left-padding {
    padding-left: 25px
}

@media (max-width: 413px) {
    .comments-area .comment-list .single-comment h5 {
        font-size: 12px
    }

    .comments-area .comment-list .single-comment .date {
        font-size: 11px
    }

    .comments-area .comment-list .single-comment .comment {
        font-size: 10px
    }
}

.comments-area .thumb {
    margin-right: 20px
}

.comments-area .thumb img {
    width: 70px;
    border-radius: 50%
}

.comments-area .date {
    font-size: 14px;
    color: #999999;
    margin-bottom: 0;
    margin-left: 20px
}

.comments-area .comment {
    margin-bottom: 10px;
    color: #777777;
    font-size: 15px
}

.comments-area .btn-reply {
    background-color: transparent;
    color: #888888;
    padding: 5px 18px;
    font-size: 14px;
    display: block;
    font-weight: 400
}

.comment-form {
    border-top: 1px solid #eee;
    padding-top: 45px;
    margin-top: 50px;
    margin-bottom: 20px
}

.comment-form .form-group {
    margin-bottom: 30px
}

.comment-form h4 {
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 22px
}

.comment-form .name {
    padding-left: 0px
}

@media (max-width: 767px) {
    .comment-form .name {
        padding-right: 0px;
        margin-bottom: 1rem
    }
}

.comment-form .email {
    padding-right: 0px
}

@media (max-width: 991px) {
    .comment-form .email {
        padding-left: 0px
    }
}

.comment-form .form-control {
    border: 1px solid #f0e9ff;
    border-radius: 5px;
    height: 48px;
    padding-left: 18px;
    font-size: 13px;
    background: transparent
}

.comment-form .form-control:focus {
    outline: 0;
    box-shadow: none
}

.comment-form .form-control::placeholder {
    font-weight: 300;
    color: #999999
}

.comment-form .form-control::placeholder {
    color: #777777
}

.comment-form textarea {
    padding-top: 18px;
    border-radius: 12px;
    height: 100% !important
}

.comment-form ::-webkit-input-placeholder {
    font-size: 13px;
    color: #777
}

.comment-form ::-moz-placeholder {
    font-size: 13px;
    color: #777
}

.comment-form :-ms-input-placeholder {
    font-size: 13px;
    color: #777
}

.comment-form :-moz-placeholder {
    font-size: 13px;
    color: #777
}

.blog_part {
    margin-bottom: 140px
}

@media (max-width: 576px) {
    .blog_part {
        margin-bottom: 0px;
        padding: 0px 0px 70px
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .blog_part {
        margin-bottom: 0px;
        padding: 0px 0px 70px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog_part {
        margin-bottom: 0px;
        padding: 0px 0px 70px
    }
}

.blog_part .blog_right_sidebar .widget_title {
    font-size: 20px;
    margin-bottom: 40px;
    font-style: inherit !important
}

@media (max-width: 576px) {
    .blog_part .single-home-blog {
        margin-bottom: 140px;
        margin-top: 20px
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .blog_part .single-home-blog {
        margin-bottom: 140px;
        margin-top: 20px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog_part .single-home-blog {
        margin-bottom: 140px;
        margin-top: 20px
    }
}

.blog_part .single-home-blog .card-img-top {
    border-radius: 0px
}

.blog_part .single-home-blog .card {
    border: 0px solid transparent;
    border-radius: 0px;
    background-color: transparent;
    position: relative
}

.blog_part .single-home-blog .card .card-body {
    padding: 25px 10px 29px 40px;
    background-color: #fff;
    position: absolute;
    left: 20px;
    bottom: -140px;
    box-shadow: -7.552px 9.326px 20px 0px rgba(1, 84, 85, 0.1);
    border-radius: 10px
}

@media (max-width: 576px) {
    .blog_part .single-home-blog .card .card-body {
        padding: 15px;
        left: 10px;
        bottom: -140px
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .blog_part .single-home-blog .card .card-body {
        padding: 15px;
        left: 10px;
        bottom: -140px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .blog_part .single-home-blog .card .card-body {
        padding: 20px
    }
}

.blog_part .single-home-blog .card .card-body a {
    color: #009DFF;
    text-transform: uppercase;
    -webkit-transition: .8s;
    transition: .8s
}

.blog_part .single-home-blog .card .card-body a:hover {
    background: -webkit-linear-gradient(131deg, #ff7e5f 0%, #feb47b 99%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent
}

.blog_part .single-home-blog .card .dot {
    position: relative;
    padding-left: 20px
}

.blog_part .single-home-blog .card .dot:after {
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    top: 5px;
    left: 0;
    background-color: #009DFF;
    border-radius: 50%
}

.blog_part .single-home-blog .card span {
    color: #8a8a8a;
    margin-bottom: 10px;
    display: inline-block;
    margin-top: 10px
}

@media (max-width: 576px) {
    .blog_part .single-home-blog .card span {
        margin-bottom: 5px;
        margin-top: 5px
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .blog_part .single-home-blog .card span {
        margin-bottom: 5px;
        margin-top: 5px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog_part .single-home-blog .card span {
        margin-bottom: 5px;
        margin-top: 5px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .blog_part .single-home-blog .card span {
        margin-bottom: 5px;
        margin-top: 5px
    }
}

.blog_part .single-home-blog .card h5 {
    font-weight: 600;
    line-height: 1.5;
    font-size: 20px;
    -webkit-transition: .8s;
    transition: .8s;
    text-transform: capitalize;
    margin-bottom: 22px
}

@media (max-width: 576px) {
    .blog_part .single-home-blog .card h5 {
        margin-bottom: 10px
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .blog_part .single-home-blog .card h5 {
        margin-bottom: 10px;
        font-size: 16px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog_part .single-home-blog .card h5 {
        margin-bottom: 10px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .blog_part .single-home-blog .card h5 {
        margin-bottom: 10px;
        font-size: 18px
    }
}

.blog_part .single-home-blog .card h5:hover {
    -webkit-transition: .8s;
    transition: .8s;
    background: -webkit-linear-gradient(131deg, #feb47b 0%, #ff7e5f 99%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: 1s;
    animation: 1s;
}

.blog_part .single-home-blog .card ul li {
    display: inline-block;
    color: #8a8a8a;
    margin-right: 14px
}

@media (max-width: 576px) {
    .blog_part .single-home-blog .card ul li {
        margin-right: 10px
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .blog_part .single-home-blog .card ul li {
        margin-right: 10px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog_part .single-home-blog .card ul li {
        margin-right: 10px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .blog_part .single-home-blog .card ul li {
        margin-right: 10px
    }
}

.blog_part .single-home-blog .card ul li span {
    margin-right: 10px;
    font-size: 12px
}

.slider_text h3 {
    font-weight: 400px;
    color: #ffffff;

    font-size: 100px;


}

.slider_text p {
    color: #ffffff;
    font-family: "Raleway", sans-serif;
    letter-spacing: 2px;
    margin-bottom: 4px
}


.blog_part .single-home-blog .card ul li span {
    margin-right: 10px;
    font-size: 8px
}

#chinhsua ul li {
    padding: 0;
    margin: 0;
    padding-right: 20px;
}

#chinhsua ul {
    display: flex;
}

.submenu {
    display: flex;
    flex-direction: column;
}

svg {
    width: 15px;
    height: 20px;
    margin-right: 5px;
    padding-top: 5px;
}

#chinhsua ul li {
    padding: 0;
    margin: 0;
    padding-right: 25px;
}

#chinhsua ul li a {
    font-size: 85%;
}

#chinhsua ul {
    display: flex;
}

.submenu {
    display: flex;
    flex-direction: column;
}

.instragram_area {
    margin-bottom: -20px;
    /* Điều chỉnh giá trị này theo nhu cầu của bạn */
}

.background-container {
    background-image: url('https://imgur.com/ob2nocC.png');
    background-size: cover;
    background-position: center;
}